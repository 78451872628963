<template>
    <vx-card :title="'Pick The Actual Items'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Canvas Item Request Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Canvas Item Request Picking Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="pickCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedTerritory" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedWarehouse" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedSales" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Loading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(loadingDate)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>

        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="table table-bordered">
                    <thead class="vs-table--thead">
                        <tr>
                            <th rowspan="2" style="vertical-align: middle; text-align: center; width: 22%">SKU Description</th>
                            <th colspan="5" style="vertical-align: middle; text-align: center">Suggestion</th>
                            <th colspan="6" style="vertical-align: middle; text-align: center">Actual</th>
                        </tr>
                        <tr>
                            <th style="vertical-align: middle; text-align: center; width: 7%">HU</th>
                            <th style="vertical-align: middle; text-align: center; width: 3%">Qty</th>
                            <th style="vertical-align: middle; text-align: center; width: 10%">Storage Area</th>
                            <th style="vertical-align: middle; text-align: center; width: 8%">Batch - Exp. Date</th>
                            <th style="vertical-align: middle; text-align: center; width: 7%">HU</th>
                            <th style="vertical-align: middle; text-align: center; width: 5%">Qty</th>
                            <th style="vertical-align: middle; text-align: center; width: 12%">Storage Area</th>
                            <th style="vertical-align: middle; text-align: center; width: 12%">Batch - Exp. Date</th>
                            <th style="vertical-align: middle; text-align: center; width: 5%">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(skuLine, index) in skuLines">
                            <tr style="padding-bottom: 5px" :key="index">
                                <td 
                                    class="td vs-table--td align-top" 
                                    style="padding: 5px"
                                    :rowspan="firstRowSpan(index)"
                                >
                                    {{ skuLine.sku_code }} - {{ skuLine.item_name }}
                                </td>

                                <!-- first row - suggestion -->
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    {{ skuLines[index].suggestion_lines[0].unit }}
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    {{ skuLines[index].suggestion_lines[0].quantity }}
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    {{ skuLines[index].suggestion_lines[0].storage_code }}
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    {{ skuLines[index].suggestion_lines[0].batch }}
                                </td>

                                <!-- first actual lines -->
                                <td class="td vs-table--td" style="padding: 5px">
                                    <v-select 
                                        :filterable="false" 
                                        :options="skuLine.option_hu"
                                        v-model="skuLines[index].actual_lines[0].selected_hu"
                                        @input="(val) => { onChangeHu(val, index, 0); }" 
                                        class="w-full"/>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <vs-input 
                                        type="number" 
                                        class="w-full" 
                                        :min=0
                                        v-model="skuLines[index].actual_lines[0].qty">
                                    </vs-input>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <v-select 
                                        :filterable="false" 
                                        :options="skuLines[index].actual_lines[0].option_storage"
                                        v-model="skuLines[index].actual_lines[0].selected_storage_area"
                                        @input="(val) => { onChangeStorage(val, index, 0); }" 
                                        class="w-full"
                                        label="label"/>
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    <v-select 
                                        :filterable="false" 
                                        :options="skuLine.actual_lines[0].option_batch"
                                        v-model="skuLines[index].actual_lines[0].batch_number"
                                        @input="(val) => { onChangeBatch(val, index, 0); }" 
                                        class="w-full"
                                        label="label"/>
                                </td>
                                <td class="td vs-table--td">
                                    <div class="vx-input-group flex mx-auto">
                                        <vs-button v-if="skuLines[index].actual_lines.length > 1"
                                            @click.stop="removeRow(index, 0)" size="small" color="danger"
                                            icon-pack="feather" icon="icon-trash" title="Remove Row"
                                            style="margin-right: 5px" />
                                        <vs-button @click.stop="addRow(index)" size="small" color="success"
                                            icon-pack="feather" icon="icon-plus" title="Add Row" />
                                    </div>
                                </td>
                            </tr>

                            <!-- added lines -->
                            <template v-for="idx in skuLines[index].suggestion_lines.length > skuLines[index].actual_lines.length ? skuLines[index].suggestion_lines.length : skuLines[index].actual_lines.length">
                                <tr style="padding-bottom: 5px" :key="index + '-' + idx" v-if="idx > 0">
                                    <!-- ITEM SUGGESTION -->
                                    <template v-if="idx < skuLines[index].suggestion_lines.length">
                                        <td class="td vs-table--td" style="padding: 5px">
                                            {{ skuLines[index].suggestion_lines[idx].unit }}
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px">
                                            {{ skuLines[index].suggestion_lines[idx].quantity }}
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px">
                                            {{ skuLines[index].suggestion_lines[idx].storage_code }}
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px">
                                            {{ skuLines[index].suggestion_lines[idx].batch }}
                                        </td>
                                    </template>

                                    <template
                                        v-if="idx == skuLines[index].suggestion_lines.length && skuLines[index].suggestion_lines.length < skuLines[index].actual_lines.length">
                                        <td class="td vs-table--td" style="padding: 5px"
                                            :rowspan="calculateRowSpan(index, idx)">
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px"
                                            :rowspan="calculateRowSpan(index, idx)">
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px"
                                            :rowspan="calculateRowSpan(index, idx)">
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px"
                                            :rowspan="calculateRowSpan(index, idx)">
                                        </td>
                                    </template>

                                    <!-- actual picking -->
                                    <template v-if="idx < skuLines[index].actual_lines.length">
                                        <td class="td vs-table--td" style="padding: 5px">
                                            <v-select 
                                                :filterable="false" 
                                                :options="skuLine.option_hu"
                                                v-model="skuLines[index].actual_lines[idx].selected_hu"
                                                @input="(val) => { onChangeHu(val, index, idx); }" 
                                                class="w-full" />
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px">
                                            <vs-input 
                                                type="number" 
                                                class="w-full" 
                                                :min=0
                                                v-model="skuLines[index].actual_lines[idx].qty">
                                            </vs-input>
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px">
                                            <v-select 
                                                :filterable="false" 
                                                :options="skuLine.actual_lines[idx].option_storage"
                                                v-model="skuLines[index].actual_lines[idx].selected_storage_area"
                                                @input="(val) => { onChangeStorage(val, index, idx); }" 
                                                class="w-full"
                                                label="label" />
                                        </td>
                                        <td class="td vs-table--td" style="padding: 5px">
                                            <v-select 
                                                :filterable="false" 
                                                :options="skuLine.actual_lines[idx].option_batch"
                                                v-model="skuLines[index].actual_lines[idx].batch_number"
                                                @input="(val) => { onChangeBatch(val, index, idx); }" 
                                                class="w-full"
                                                label="label" />
                                        </td>
                                        <td class="td vs-table--td">
                                            <div class="vx-input-group flex mx-auto">
                                                <vs-button v-if="skuLines[index].actual_lines.length > 1"
                                                    @click.stop="removeRow(index, idx)" size="small" color="danger"
                                                    icon-pack="feather" icon="icon-trash" title="Remove Row" />
                                            </div>
                                        </td>
                                    </template>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import vSelect from "vue-select";
import Swal from 'sweetalert2'

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            id: null,
            readonly: true,
            action: null,
            status: null,
            code: null,
            pickID: null,
            pickCode: null,
            selectedWarehouse: null,
            warehouseID: 0,
            selectedTerritory: null,
            selectedSales: null,
            loadingDate: null,
            skuLines: [
                {
                    sku_code: null,
                    item_name: null,
                    option_hu: [],
                    suggestion_lines: [
                        {
                            suggest_id: 0,
                            unit: null,
                            quantity: 0,
                            storage_code: null,
                            batch: null,
                            expired_date: null,
                        },
                    ],
                    actual_lines: [
                        {
                            item_stock_id: 0,
                            selected_hu: null,
                            qty: 0,
                            option_storage: [],
                            selected_storage_area: null,
                            option_batch: [],
                            batch_number: null,
                            batch: null,
                            expired_date: null,
                        },
                    ],
                },
            ],
        };
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData();
        }
    },
    methods: {
        getData() {
            this.action = "form";
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/canvas/loading/get-picking/" + this.id + "/" + this.action)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.status = resp.data.status;
                        this.code = resp.data.code;
                        this.selectedWarehouse = resp.data.selected_warehouse.label;
                        this.warehouseID = resp.data.selected_warehouse.id;
                        this.selectedTerritory = resp.data.selected_territory.label;
                        this.selectedSales = resp.data.selected_sales.label;
                        this.loadingDate = moment(resp.data.loading_date).toDate();
                        this.pickID = resp.data.pick_id;
                        this.pickCode = resp.data.pick_code;
                        this.skuLines = resp.data.sku_lines;

                        this.skuLines.forEach((val) => {
                            val.actual_lines.forEach((valIdx) => {
                                if (valIdx.item_stock_id != 0) {
                                    valIdx.expired_date = this.formatDate(valIdx.expired_date);
                                }
                            });
                        });

                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: error,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                });
        },
        handleBack() {
            this.$router.push({
                name: "canvas.loading",
                params: {status: 1},
            });
        },
        handleSubmit() {
            let message = "";
            let skus = [];
            this.skuLines.forEach((val) => {
                let actLines = [];

                val.actual_lines.forEach((valActLine) => {
                    if (valActLine.selected_hu == null) {
                        message = "please check hu, hu is required";
                        return;
                    }

                    if (valActLine.qty < 1) {
                        message = "qty is required more than zero";
                        return;
                    }

                    if (valActLine.selected_storage_area == null) {
                        message = "please select storage area";
                        return;
                    }

                    if (valActLine.batch_number == null) {
                        message = "please select batch number";
                        return;
                    }

                    actLines.push({
                        item_stock_id: valActLine.item_stock_id == null ? 0 : valActLine.item_stock_id,
                        selected_hu: valActLine.selected_hu,
                        qty: parseInt(valActLine.qty),
                        storage_area: (valActLine.selected_storage_area.label) ? valActLine.selected_storage_area.label : valActLine.selected_storage_area,
                        batch_number: (valActLine.batch_number.batch) ? valActLine.batch_number.batch : valActLine.batch,
                        expired_date: (valActLine.batch_number.expired_date) ? valActLine.batch_number.expired_date : valActLine.expired_date,
                    });
                });

                skus.push({
                    sku_code: val.sku_code,
                    actual_lines: actLines,
                });
            });

            if (message != "") {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }

            let formData = new FormData();
            formData.append("skus", JSON.stringify(skus));

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Submit`,
                text: "Are you sure to submit this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .put("/api/wms/v1/canvas/loading/save-actual/" + this.id, formData)
                        .then((resp) => {
                            if (resp.code == 200) {
                                Swal.fire(
                                    "Done!",
                                    "Picking process has been updated.",
                                    "success"
                                );
                                this.$vs.loading.close();
                                this.handleBack()
                            } else {
                                this.$vs.loading.close();
                                Swal.fire({
                                    title: "Something went wrong",
                                    text: resp.message,
                                    icon: "info"
                                });
                            }
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                            Swal.fire({
                                title: 'Error occured',
                                text: error,
                                icon: 'error',
                            })
                        });
                }
            });
        },
        onChangeHu(val, index, idx) {
            this.action = "selected_hu"
            this.skuLines[index].actual_lines[idx].selected_hu = val
            this.skuLines[index].actual_lines[idx].qty = 0
            this.skuLines[index].actual_lines[idx].selected_storage_area = null
            this.skuLines[index].actual_lines[idx].batch_number = null
            this.skuLines[index].actual_lines[idx].expired_date = null
            if (this.skuLines[index].actual_lines[idx].selected_hu) {
                this.$vs.loading();
                this.$http
                    .get("/api/wms/v1/canvas/loading/get-dropdown-opt/" + this.action, {
                        params: {
                            pick_id: this.pickID,
                            sku_code: this.skuLines[index].sku_code,
                            selected_hu: this.skuLines[index].actual_lines[idx].selected_hu,
                            warehouse_id: this.warehouseID,
                        },
                    })
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$set(this.skuLines[index].actual_lines[idx], 'option_storage', resp.data.storage_option);
                        }
                        this.$vs.loading.close();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
            }
        },
        onChangeStorage(val, index, idx) {
            this.action = "selected_storage"
            this.skuLines[index].actual_lines[idx].selected_storage_area = val
            this.skuLines[index].actual_lines[idx].batch_number = null
            this.skuLines[index].actual_lines[idx].expired_date = null
            if (this.skuLines[index].actual_lines[idx].selected_storage_area) {
                this.$vs.loading();
                this.$http
                    .get("/api/wms/v1/canvas/loading/get-dropdown-opt/" + this.action, {
                        params: {
                            pick_id: this.pickID,
                            sku_code: this.skuLines[index].sku_code,
                            selected_hu: this.skuLines[index].actual_lines[idx].selected_hu,
                            warehouse_area_code: this.skuLines[index].actual_lines[idx].selected_storage_area.label,
                            warehouse_id: this.warehouseID,
                        },
                    })
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$set(this.skuLines[index].actual_lines[idx], 'option_batch', resp.data.storage_option);
                        }
                        this.$vs.loading.close();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
            }
        },
        onChangeBatch(val, index, idx) {
            this.action = "selected_batch"
            this.skuLines[index].actual_lines[idx].batch_number = val
        },
        removeRow(index, idx) {
            this.skuLines[index].actual_lines.splice(idx, 1);
        },
        addRow(index) {
            this.skuLines[index].actual_lines.push({
                item_stock_id: 0,
                selected_hu: null,
                qty: 0,
                selected_storage_area: null,
                batch_number: null,
                expired_date: null,
            });
        },
        calculateRowSpan(index, idx) {
            let suggestionRowCount = this.skuLines[index].suggestion_lines.length;
            let actualRowCount = this.skuLines[index].actual_lines.length;

            if (suggestionRowCount < actualRowCount) {
                return actualRowCount - suggestionRowCount == 0
                    ? null
                    : actualRowCount - suggestionRowCount;
            }

            if (this.skuLines[index].suggestion_lines.length == 2) {
                let lastSuggestionIndex = suggestionRowCount > 0 ? suggestionRowCount - 1 : 0;
                let startActualIndex = lastSuggestionIndex;
                let totalRows = Math.max(suggestionRowCount, actualRowCount + startActualIndex);

                return totalRows;
            } else {
                return null;
            }
        },
        firstRowSpan(index) {
            return this.skuLines[index].suggestion_lines.length >
                this.skuLines[index].actual_lines.length
                ? this.skuLines[index].suggestion_lines.length
                : this.skuLines[index].actual_lines.length;
        },
    },
    watch: {},
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD");
        },
    },
};
</script>
